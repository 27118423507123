import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Slider } from 'primereact/slider';
import { Toast } from 'primereact/toast';
        

const HomeScreen = (props) => {
    const toast = useRef(null);
    const [answers, setAnswers] = useState('');
    const [data, setData] = useState([]);
    const [audience, setAudience] = useState('');
    const [audienceData, setAudienceData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        name: '',
        audience_id: null,
        sample_size: 15,
        question: '',
        answers: []
    });

    const nav = useNavigate();

    // Create Survey
    // name: str
    // question: str
    // answers: List[str]
    // audience_id: UUID
    // sample_size: int
    const createSurvey = () => {
        if (formData.audience_id === null) {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please choose an audience', life: 3000});
            return
        }
        if (formData.answers === '') {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter some answers', life: 3000});
            return
        }
        if (formData.name === '') {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter a name', life: 3000});
            return
        }
        if (formData.question === '') {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter a question', life: 3000});
            return
        }
        formData.answers = answers.split("\n")
        // formData.audience_id = 'c0a89564-9067-401c-a872-c6bc1007aac6' // rich democrat
        // formData.audience_id = 'ea85e55c-fc95-4564-8642-d27a897a0d1a' // rich republican

        fetch(props.baseUrl +'/surveys', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // console.log('Success:', data);
            nav('/surveys/'+data.id)
        })
        .catch(error => {
            console.error('Error:', error);
        });

    };

    useEffect(() => {
        // Function to fetch data from the API
        const fetchSurveyData = async () => {
            try {
                const response = await fetch(props.baseUrl +'/surveys');
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchAudienceData = async () => {
            try {
                const response = await fetch(props.baseUrl +'/audiences');
                const result = await response.json();
                for (const a in result) {
                    result[a].criteriastr = JSON.stringify(result[a].criteria)
                }
                setAudienceData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSurveyData();
        fetchAudienceData();
    }, []); // Empty dependency array means this effect runs once on mount

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    function onRowClick(event) {
        nav('/surveys/'+event.data.id)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log(formData);
    };

    function onAudienceChange(event) {
        if (event.value) {
            console.log('audience changed',event.value)
            setAudience(event.value)
            // formData.audience_id = audience
            setFormData({ ...formData, ['audience_id']: event.value })
        }

    }

    const itemTemplate = (item) => {
        // console.log('item', item)
        // console.log(item.title, item.criteria, item.hasOwnProperty('criteria'))
        var crit = ""
        if (item.criteria === null) {
            // console.log(item.title, 'empty')
        } else {
            var vals = []
            for (const c in item.criteria) {
                var tmp = c+"=["
                if (Array.isArray(item.criteria[c])) {
                    tmp += item.criteria[c].join(',')
                }
                tmp += "]"
                vals.push(tmp)
            }
            crit = vals.join(', ')
        }
        return (
            <div className="align-items-center">
                <div className="">
                    <span className="font-bold text-900">{item.title}</span>
                    <span> ({item.total_users} users)</span>
                </div>
                <div className=""> &nbsp;
                    {crit}
                </div>
            </div>
        );
    };

    return (
        <div className="App">
            <Toast ref={toast} position='top-left' />
            <h1>AI Surveys</h1>
            <form onSubmit={handleSubmit} className="p-fluid my-form">
                <div className="p-field p-grid">
                    <label htmlFor="titleInput" className="p-col-fixed" style={{ width: '100px'}}>Title</label>
                    <div className="p-col">
                        <InputText
                            id="titleInput"
                            name="name"
                            value={formData.name}
                            onChange={handleChange} 
                            placeholder="Enter a survey title"
                        />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="sampleSizeInput" className="p-col-fixed" style={{ width: '100px'}}>Respondents</label>
                    <div className="p-col">
                        <InputText
                            id="sampleSizeInput"
                            name="sample_size"
                            value={formData.sample_size}
                            onChange={handleChange} 
                            readOnly
                            placeholder="Enter a count of users"
                        />
                        <Slider
                            id="sampleSizeSlider"
                            name="sample_size_slider"
                            value={formData.sample_size}
                            min={1}
                            max={250}
                            onChange={(e) => setFormData({ ...formData, ['sample_size']: e.value })} 
                            className="w-14rem" />

                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="audienceList" className="p-col-fixed" style={{ width: '100px'}}>Audience</label>
                    <div className="p-col">
                    <Dropdown
                        filter
                        filterBy='title,criteriastr'
                        id="audienceList"
                        dataKey="id"
                        value={audience}
                        options={audienceData}
                        onChange={onAudienceChange}
                        itemTemplate={itemTemplate}
                        optionLabel="title"
                        optionValue="id"
                        emptyMessage="No audiences found"
                        placeholder="Choose an audience"
                        />
    
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="questionInput" className="p-col-fixed" style={{ width: '100px'}}>Question</label>
                    <div className="p-col">
                        <InputText
                            id="questionInput"
                            name="question"
                            value={formData.question}
                            onChange={handleChange} 
                            placeholder="Enter a question"
                        />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="answersInput" className="p-col-fixed" style={{ width: '100px'}}>Answers</label>
                    <div className="p-col">
                        <InputTextarea
                            id="answersInput"
                            value={answers}
                            onChange={(e) => setAnswers(e.target.value)}
                            placeholder="Enter the answers        (one per line)"
                            rows={5}
                        />
                    </div>
                </div>
                <Button type="submit" label="Run Survey" onClick={createSurvey} style={{width: '220px', 'marginLeft': '115px'}} />
            </form>

            <h2>Surveys</h2>
            <DataTable style={{ cursor:'pointer'}}  value={data} loading={loading} onRowClick={onRowClick} emptyMessage="No surveys found">
                <Column field="created_at" header="Created" body={(rowData) => formatDate(rowData.created_at)}></Column>
                <Column field="name" header="Name"></Column>
                <Column field="status" header="Status"></Column>
                <Column field="count" header="Count"></Column>
                {/* <Column field="id" header="Results">
                    <Button className="p-button" label="View"></Button>
                </Column> */}
            </DataTable>
        </div>
    );
}

export default HomeScreen;

