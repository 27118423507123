import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';

import HomeScreen from './home'
import ResultsScreen from './results'

const App = () => {
    const baseUrl = 'http://localhost:8000/api'
    // const baseUrl = 'https://ai-beta.invisibly.com/api'
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<HomeScreen baseUrl={baseUrl} />} />
                <Route path="/surveys/:id" element={<ResultsScreen baseUrl={baseUrl} />} />
            </Routes>
        </div>
    );
}

export default App;

