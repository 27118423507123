import { useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { BreadCrumb } from 'primereact/breadcrumb';


const ResultsScreen = (props) => {
    const items = [{ label: 'Results' }];
    const home = { icon: 'pi pi-home', url: '/' }
    var interval = null

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [criteria, setCriteria] = useState([]);
    const [survey, setSurvey] = useState({
        questions: [{
        title: '',
        question: '',
        answers: [],
        completedCount: 0
    }]});

    const percentBodyTemplate = (answer) => {
        return <ProgressBar value={answer['percentage']}></ProgressBar>
      }

    const parseCriteria = (criteria) => {
        var crit = ""
        if (criteria === null) {
            // console.log(item.title, 'empty')
        } else {
            var vals = []
            for (const c in criteria) {
                var tmp = c+" = "
                if (Array.isArray(criteria[c])) {
                    tmp += criteria[c].join(', ')
                }
                tmp += ""
                vals.push(tmp)
            }
            // crit = vals.join(<br/>)
            setCriteria(vals)
        }
    }
    // Function to fetch data from the API
    const fetchData = async () => {
        try {
            const response = await fetch(props.baseUrl +'/answers/' + params.id);
            const result = await response.json();
            setSurvey(result);
            parseCriteria(result.audience.criteria)
            if (result.status !== 'completed' && interval === null) {
                console.log('setting timer')
                interval = setInterval(() => fetchData(), 5000);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <>
        <BreadCrumb model={items} home={home} />
        { !loading &&
        <div className="mx-4 my-4">
          <Card title= {survey?.title}>
            <div className="grid">
              <div className="col">
                <span className="p-float-label font-bold">
                                Total Respondents
                </span>
                <span>
                  {survey.completedCount?.toString()}
                </span>
              </div>
              <div className="col">
                <span className="p-float-label font-bold">
                                Status
                </span>
                <span>
                  {survey.status}
                </span>
              </div>
              <div className="col">
                <span className="p-float-label font-bold">
                                Audience
                </span>
                <span>
                  {survey.audience.title}<br />
                  <ul>
                    <li>{survey.audience.total_users} users</li>
                  { criteria.map((c, i) => {
                    return(
                        <li key={i}>{c}</li>
                    )
                  })}
                  </ul>
                </span>
              </div>
            </div>
          </Card>
          <div className="grid">
          { survey.questions.map((question, i) => {
            return(
                <div className="col" key={i}>
                    <div>
                        <h3>{question.title}</h3>
                        <DataTable showGridlines={true} size="small" value={question.answers} style={{ cursor:'pointer'}} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="title" header="Answer"  style={{ width: '70%' }} sortable></Column>
                        <Column field="count" header="Count"  style={{ width: '10%' }} sortable></Column>
                        <Column field="percent" header="Percent"  style={{ width: '20%' }} body={percentBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            )
        })}
          </div>
        </div>
        }
        { loading &&  
            <div className="mx-4 my-4">
              <div className="grid">
                <div className="col">
                  <ProgressSpinner />
                </div>
              </div>
            </div>
            }
        </>
    );
}

export default ResultsScreen;

